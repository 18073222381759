import { useEffect } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useWindowSize } from '../../hook/useWindowSize';
import { IconDownload } from '../../images/icon-download';

type FullscreenMediaProps = {
  src: string;
  toggleFullscreen: () => void;
};

export const FullscreenMedia = (props: FullscreenMediaProps) => {
  const { src, toggleFullscreen } = props;

  const handleDownload = async () => {
    const image = await fetch(src);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const name = src.substring(src.lastIndexOf('/') + 1, src.length);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const size = useWindowSize();

  useEffect(() => {
    const app: HTMLDivElement | null = document.querySelector('.app-wrapper');
    app!.classList.add('isFullscreen');
    return () => {
      app!.classList.remove('isFullscreen');
    };
  }, []);

  return (
    <div
      className="fullscreen-media"
      style={{ width: `${size.width}px`, height: `${size.height}px` }}
    >
      <div className="media" style={{ backgroundImage: `url(${src})` }} />
      <div className="media-actions">
        <div className="media-actions-item" onClick={toggleFullscreen}>
          <IoIosCloseCircleOutline color="white" size="2rem" />
        </div>
        <div className="media-actions-item" onClick={handleDownload}>
          <IconDownload />
        </div>
      </div>
    </div>
  );
};
