import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import { searchInitialValues } from './FormType';

import './nftForm.scss';
import { useHistory, useParams } from 'react-router-dom';

const NftForm = () => {
  const history = useHistory();
  const params = useParams();
  const { network }: any = params;

  return (
    <>
      <div className="container form-wrapper">
        <div className="form-container">
          <h1>Visualize an NFT</h1>
          <p>
            Visualize all XLS20D NFTs minted according to the <b>Aesthetes' standards.</b> <br />
            <i>
              <b>Join the XRPL NFTs Revolution</b>
            </i>
          </p>

          <Formik
            initialValues={searchInitialValues}
            onSubmit={(values) => {
              history.push(`/${network}/nft-data/${values.issuer}/${values.id}`);
            }}
          >
            {({ handleChange, handleSubmit, errors, values, setFieldValue }) => {
              return (
                <div className="form">
                  <div className="form-input">
                    <input
                      className={`input ${errors.issuer && values.issuer !== '' ? 'error' : ''}`}
                      placeholder="NFT owner"
                      value={values.issuer}
                      onChange={(e) => setFieldValue('issuer', e.target.value)}
                    />
                    <input
                      className={`input ${errors.id && values.id !== '' ? 'error' : ''}`}
                      placeholder="NFT identifier"
                      value={values.id}
                      onChange={(e) => setFieldValue('id', e.target.value)}
                    />
                  </div>
                  {/*
                <RadioGroup defaultValue={network} className="radio-btn-container">
                  <div className="radio-container">
                    <Radio
                      value="testnet"
                      checked={network === 'testnet'}
                      onChange={() => history.push('/testnet')}
                    >
                      Testnet
                    </Radio>
                  </div>
                  <div className="radio-container">
                    <Radio
                      value="XLS20d"
                      checked={network === 'XLS20d'}
                      onChange={() => history.push('/XLS20d')}
                    >
                      XLS20d
                    </Radio>
                  </div>
                </RadioGroup>
*/}
                  <div className="btn-container">
                    <button
                      disabled={
                        // disabled if both inputs are empty or if there are errors
                        Object.values(values).some((v) => v === '') || !isEmpty(errors)
                      }
                      className="action-btn button-hover"
                      onClick={() => handleSubmit()}
                    >
                      {/* {t("nftForm.visualize")} */}
                      Visualize
                    </button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default NftForm;
