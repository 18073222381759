import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { IconSwipe } from '../../images/icon-swipe';
import './LandingPage.scss';

export default function LandingPage() {
  const history = useHistory();
  const enabled = useRef(true);

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1023);

  const handlers = useSwipeable({
    onSwiped: () => {
      history.push('/XLS20d');
    },
    delta: 50, // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: true, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0 // set a rotation angle
  });

  const handleRedirect = useCallback(() => {
    if (enabled.current && !isMobile) {
      enabled.current = false;
      //history.push('/XLS20d');
      history.push('/Mainnet');
    }
  }, [history, isMobile]);

  const onResize = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleRedirect);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('wheel', handleRedirect);
      window.removeEventListener('resize', onResize);
    };
  }, [handleRedirect]);

  return (
    <>
      <div className="container landing-wrapper" {...handlers}>
        <div className="hero-container">
          <h2>Discover the new</h2>
          <h1>NFT Visualizer</h1>
          <div className="cta">
            {isMobile ? 'Swipe' : 'Scroll'} to &nbsp; <b>begin your journey</b>
            {isMobile ? <SwipeIcon /> : <ScrollIcon />}
          </div>
        </div>
      </div>
    </>
  );
}

const SwipeIcon = () => {
  return (
    <div className="scroll-swipe">
      <IconSwipe />
    </div>
  );
};

const ScrollIcon = () => {
  return (
    <div className="scroll-icon">
      <div className="dot" />
    </div>
  );
};
