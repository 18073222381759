import { useEffect } from 'react';

type AppLoaderProps = {
  showApp: boolean;
};

export const AppLoader = (props: AppLoaderProps) => {
  const { showApp } = props;

  useEffect(() => {
    const circle = document.querySelector('.logo-stroke circle');
    circle?.classList.add('circle-animation');
  }, []);

  return (
    <div className={`app-loader ${showApp ? 'hidden' : ''}`}>
      <div className="loader">
        <svg x="0px" y="0px" viewBox="0 0 67 67" className="logo-stroke">
          <circle className="circle-animation" cx="50%" cy="50%" r={32} />
        </svg>
        <svg x="0px" y="0px" viewBox="0 0 67 67" className="logo-text">
          <path
            d="M35.653 13.465h-4.32l-15.12 34.56h4.703l21.265-8.784 3.84 8.784h4.752l-15.12-34.56zM23.413 42.36l10.08-23.04 6.96 15.936-17.04 7.104z"
            fill="#fff"
          />
        </svg>
      </div>
    </div>
  );
};
