export interface INftData {
  identifier: string;
  actual_nft_owner: string;
  detected_hot_wallet_obj: string;
  metadata_cid: string;
  detected_cti: string;
  metadata_tx_hash: string;
  name: string;
  description: string;
  author: string;
  issuer_address: string;
  issuer_domain: string;
  nft_serial: string;
  content_cid: string;
  metadata_link: string;
  content_link: string;
  content_link_blob: string;
  NFToken_uris_obj: {
    TokenSeq: number;
    NFTokenTaxon: number;
  };
  NFToken_flags: {
    tfBurnable: boolean;
    tfOnlyXRP: boolean;
    tfTransferable: boolean;
    tfTrustLine: boolean;
  };
  warnings: any;
}

export const checkIsCertified = (nftData: INftData) => {
  if (
    !nftData.warnings.includes('NoIssuerDomain') &&
    !nftData.warnings.includes('IssuerNotVerified')
  ) {
    if (
      nftData.issuer_domain === 'aesthetes.art' ||
      nftData.issuer_domain.endsWith('.aesthetes.art')
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const copyToClipboard = () => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(window.location.href);
  } else {
    console.log('Copy not available');
  }
};

export const fetchMedia = async (url: string) => {
  // return new Promise((resolve) => {
  //   const img = new Image();
  //   img.src = url;
  //   img.onload = () => resolve(true);
  //   img.onerror = () => resolve(false);
  // });

  const res = await fetch(url);
  return res;
};
