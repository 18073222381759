import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../hook/useWindowSize';
import Logo from '../../images/logo';
import { Burger } from './burger';
import { NavDesktop } from './nav-desktop';
import { NavMobile } from './nav-mobile';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const location = useLocation();
  const size = useWindowSize();

  const buttonHomeActive = location.pathname === '/';

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header container">
      <Link to="/" className={`logo ${buttonHomeActive ? 'disabled' : ''}`}>
        <Logo />
      </Link>

      <NavDesktop />
      {menuOpen && <NavMobile onClose={toggleMenu} height={size.height} />}

      <Burger state={menuOpen} onClick={toggleMenu} />
    </div>
  );
};
