export const IconAdd = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 24 24">
      <g>
        <path
          fill="#ffffff"
          d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2
		s10,4.5,10,10S17.5,22,12,22z"
        />
      </g>
      <g>
        <path
          fill="#ffffff"
          d="M17.5,12c0,0.5-0.5,1-1,1H13v3.5c0,0.5-0.5,1-1,1s-1-0.5-1-1V13H7.5c-0.5,0-1-0.5-1-1s0.5-1,1-1H11V7.5
		c0-0.5,0.5-1,1-1s1,0.5,1,1V11h3.5C17,11,17.5,11.5,17.5,12z"
        />
      </g>
    </svg>
  );
};
