import { 
    isUndefinedOrNull, retrieveUrlParameters
 } from "./utils.js";
 
 var xumm_parameters: any = null;
 var payload_uuid = null;
 var user_token = null;

 let initialized = false;
 let _isXumm = false;
 
 export const isXumm = function(){
   if (!initialized) {
    if(typeof(navigator) !== 'undefined' && /xumm/i.test(navigator.userAgent)){
      if(xumm_parameters === null){ //if xumm_parameters has not been already initialized
        extractXappParameters();
        //console.log("isXumm(): xumm_parameters: ", xumm_parameters)
      }
      _isXumm = true;
    } else {
      _isXumm = false;
    }
    initialized = true;
  }
   return _isXumm;
 }
 
export function openExternal(url: string, target: '_blank'|'_parent'|'_self'|'_top' = '_blank') {
  if (isXumm()) { handleXUMMRedirection(url); }
  else {
    window.open(url, target)
  }
}

 function postXUMMCommand(XUMM_command_obj: any){
   if(typeof (window as any).ReactNativeWebView !== "undefined"){
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(XUMM_command_obj));
   }
 }
 export const handleXUMMRedirection = function(_url: string){
   if(isUndefinedOrNull(_url) || _url.length === 0){
     return;
   }
   
   const XUMM_command_obj = {
     "command": "openBrowser",
     "url": _url
   };
   
   postXUMMCommand(XUMM_command_obj);
 }
 export const handleXUMMSignFlow = function(payload_uuid: string){
   const XUMM_command_obj = {
     "command": "openSignRequest",
     "uuid": payload_uuid
   };
   
   postXUMMCommand(XUMM_command_obj);
 }
 
 async function callRestApiWrapper(axios_handler: any, method: any, endpoint_url: string, data = {}){
   const x_app_token = xumm_parameters["xAppToken"];
   
   const _data = {
     ...data,
     "xAppToken": x_app_token
   };
   return axios_handler.callRestApi(method, endpoint_url, _data);  
 }
 
 export const extractXappParameters = function(){
   const url_parameters: any = retrieveUrlParameters();
   
   const xAppToken_array = url_parameters["xAppToken"];
   if(isUndefinedOrNull(xAppToken_array) || xAppToken_array.length === 0){
     return false;
   }
   xumm_parameters["xAppToken"] = xAppToken_array[0];
   
   const xAppStyle_array = url_parameters["xAppStyle"];
   if(isUndefinedOrNull(xAppStyle_array) || xAppStyle_array.length === 0){
     xumm_parameters["xAppStyle"] = "LIGHT"; //LIGHT is the default
     return true;
   }
   xumm_parameters["xAppStyle"] = xAppStyle_array[0];
   
   return true;
 }