import './errorPage.scss';
import { useHistory } from 'react-router';

const ErrorPage = () => {
  const history = useHistory();

  return (
    <div className="container error-page-container">
      <div className="back-btn">
        <div onClick={() => history.push('/XLS20d')}>SEARCH ANOTHER NFT</div>
      </div>
    </div>
  );
};

export default ErrorPage;
