import Copy from '../copy';
import Social from '../social';
import './Footer.scss';

const Footer = () => (
  <div className="footer container">
    <Social />
    <div className="footer-hr" />
    <Copy />
  </div>
);

export default Footer;
