import { Link, useLocation } from 'react-router-dom';
import { openExternal } from '../../imports/scripts/xumm_handler';

export const NavDesktop = () => {
  const location = useLocation();
  const buttonVisualizerActive = location.pathname === '/XLS20d';

  return (
    <div className="navigation-desktop">
      <Link to="/XLS20d" className={`nav-item ${buttonVisualizerActive ? 'disabled' : ''}`}>
        THE NFT VISUALIZER
      </Link>
      <a
        href="https://www.aesthetes.art"
        className="nav-item"
        onClick={(e) => {
          e.preventDefault();
          openExternal('https://www.aesthetes.art');
        }}
      >
        AESTHETES PROJECT
      </a>
    </div>
  );
};
