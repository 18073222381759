import { checkIsCertified, copyToClipboard, INftData } from './nft.utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FullscreenMedia } from './fullscreen-media';
import { IconCertified } from '../../images/icon-certified';
import { IconShare } from '../../images/icon-share';
import { IconAdd } from '../../images/icon-add';

type NftMediaProps = {
  nftData: INftData;
};

export const NftMedia = (props: NftMediaProps) => {
  const { nftData } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const [fullScreen, setFullscreen] = useState<boolean>(false);
  const [certified, setCertified] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [type, setType] = useState<'IMAGE' | 'VIDEO' | null>(null);

  const toggleFullscreen = () => {
    setFullscreen(!fullScreen);
  };

  const onCopy = () => {
    copyToClipboard();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const setMediaType = useCallback(() => {
    fetch(nftData.content_link_blob).then((response) => {
      const type = response.headers.get('Content-Type');
      setType(type?.includes('image') ? 'IMAGE' : 'VIDEO');
    });
  }, [nftData.content_link_blob]);

  useEffect(() => {
    setCertified(checkIsCertified(nftData));
    setMediaType();
  }, [nftData, setMediaType]);

  return (
    <>
      <div className="nft-media">
        <div className="image-container" ref={containerRef}>
          {type === 'IMAGE' && <img src={nftData.content_link_blob} alt="" />}
          {type === 'VIDEO' && (
            <video
              src={nftData.content_link_blob}
              controls
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture
            />
          )}
        </div>

        {certified && (
          <div className="certified">
            <IconCertified />
          </div>
        )}
        <div className="media-actions">
          {type === 'IMAGE' && (
            <div className="media-actions-item" onClick={toggleFullscreen}>
              <IconAdd />
            </div>
          )}
          <div className="media-actions-item" onClick={onCopy}>
            <IconShare />
            {copied && <div className="copied">Link copied</div>}
          </div>
        </div>
      </div>

      {fullScreen && (
        <FullscreenMedia src={nftData.content_link_blob} toggleFullscreen={toggleFullscreen} />
      )}
    </>
  );
};
