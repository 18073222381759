import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';

import ErrorPage from './pages/error/ErrorPage';
import LandingPage from './pages/landingPage/LandingPage';
import NftForm from './pages/nftForm/nftForm';
import NftDataPage from './pages/nftData/NftData';
import { CSSProperties } from 'react';

export const Routes = () => {
  const location = useLocation();
  const reverse = !location.pathname.includes('XLS20d');

  const defaultProps: CSSProperties = {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex'
  };

  const transitions = useTransition(location, {
    from: {
      ...defaultProps,
      transform: reverse ? 'translate(-100%,0)' : 'translate(100%,0)'
    },
    enter: {
      ...defaultProps,
      transform: 'translate(0%,0)'
    },
    leave: {
      ...defaultProps,
      transform: reverse ? 'translate(100%,0)' : 'translate(-100%,0)'
    }
  });

  return (
    <>
      {transitions((props: any, item: any) => (
        <animated.div style={props as any}>
          <Switch location={item}>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/:network" component={NftForm} />
            <Route exact path="/:network/nft-data/:issuer/:id" component={NftDataPage} />
            <Route exact path="/:network/error" component={ErrorPage} />
          </Switch>
        </animated.div>
      ))}
    </>
  );
};
