type BurgerProps = {
  className?: string;
  state: boolean;
  onClick: () => void;
};

export const Burger = (props: BurgerProps) => {
  const { className, state, onClick } = props;

  return (
    <div className={`${className || ''} burger-menu ${state ? 'open' : ''} `} onClick={onClick}>
      <div className="dish">
        <div className="burger-item bread-top"></div>
        <div className="burger-item meat"></div>
        <div className="burger-item bread-bottom"></div>
      </div>
    </div>
  );
};
