export const IconCertified = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 125 125">
      <path fill="#ffffff" d="M6.3,46.6l-0.8-0.2l0.9-3l0.8,0.2L6.3,46.6z" />
      <path
        fill="#ffffff"
        d="M14,34l-0.4,0.8l-1.8-0.1l-0.4,4.2l-0.4,0.8l-4.4-5.4l0.4-0.7L14,34z M11.1,34.6l-3.2-0.2l2.9,3.6L11.1,34.6z"
      />
      <path
        fill="#ffffff"
        d="M10.6,29.2l1.3,0.9l1.5-2.3l0.7,0.4l-1.6,2.3l2,1.3l2-3l0.7,0.4L14.7,33l-5.3-3.6l2.5-3.7l0.7,0.4L10.6,29.2z"
      />
      <path
        fill="#ffffff"
        d="M19.4,23.7c0.4,0.3,0.6,0.7,0.6,1.2c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.6l-1.6,1.9l-0.6-0.5l1.6-1.9
   c0.2-0.2,0.3-0.5,0.2-0.7c0-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4,0c-0.3,0-0.5,0.1-0.7,0.3L17,25.1
   c-0.3,0.4-0.7,0.6-1.2,0.6c-0.5,0-0.9-0.1-1.3-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.5,0.1-0.9,0.4-1.3l1.4-1.7l0.6,0.5l-1.4,1.7
   c-0.2,0.2-0.2,0.5-0.2,0.7c0,0.3,0.2,0.5,0.4,0.7c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.4,0c0.3,0,0.5-0.1,0.7-0.3l0.5-0.6
   c0.3-0.4,0.7-0.6,1.2-0.6C18.6,23.3,19.1,23.4,19.4,23.7z"
      />
      <path
        fill="#ffffff"
        d="M20.7,16.1l0.6,0.6L19.8,18l3.9,4l-0.6,0.6l-3.9-4l-1.4,1.4l-0.5-0.6L20.7,16.1z"
      />
      <path
        fill="#ffffff"
        d="M25.9,11.8l3.9,5l-0.6,0.5l-2.1-2.7l-1.7,3l1.3,1.6l-0.6,0.5l-3.9-5l0.6-0.5l2.1,2.7l1.7-3l-1.3-1.6L25.9,11.8z
   "
      />
      <path
        fill="#ffffff"
        d="M28.7,11l0.8,1.3l2.3-1.5l0.4,0.7L29.9,13l1.3,2l3-1.9l0.4,0.7l-3.7,2.3l-3.4-5.4l3.7-2.3L31.7,9L28.7,11z"
      />
      <path
        fill="#ffffff"
        d="M37.2,5.3L37.6,6l-1.8,0.9l2.4,5l-0.7,0.4l-2.4-5l-1.8,0.9l-0.3-0.7L37.2,5.3z"
      />
      <path
        fill="#ffffff"
        d="M40.1,5l0.5,1.5l2.7-1l0.3,0.7l-2.6,1l0.8,2.2l3.4-1.2L45.4,9l-4.1,1.5l-2.2-6L43.2,3l0.3,0.8L40.1,5z"
      />
      <path
        fill="#ffffff"
        d="M50.4,5.8c0.1,0.2,0.1,0.5,0,0.7c0,0.2-0.1,0.5-0.2,0.7C50,7.6,49.6,7.9,49.1,8l-2.4,0.6l-0.2-0.8L49,7.2
   c0.3-0.1,0.5-0.2,0.6-0.5c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3,0-0.4c-0.1-0.3-0.2-0.5-0.4-0.6c-0.2-0.1-0.5-0.2-0.8-0.1l-0.8,0.2
   c-0.5,0.1-0.9,0-1.3-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.5,0-0.9,0.2-1.3c0.1-0.2,0.3-0.4,0.5-0.5
   c0.2-0.1,0.4-0.2,0.6-0.3l2.2-0.5l0.2,0.8L47,2.8c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0,0.4,0.1,0.6
   c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.2,0.8,0.1l0.8-0.2c0.2-0.1,0.5-0.1,0.7,0s0.5,0.1,0.7,0.2C49.8,4.8,50,5,50.1,5.2
   C50.3,5.4,50.4,5.6,50.4,5.8z"
      />
      <path
        fill="#ffffff"
        d="M60.6,5l0.6,0.5c-0.4,0.4-0.8,0.7-1.3,0.9c-0.5,0.2-1,0.3-1.6,0.2c-0.5-0.1-1-0.3-1.5-0.6
   c-0.4-0.3-0.8-0.7-1-1.2c-0.2-0.5-0.4-1-0.3-1.6c0-0.5,0.2-1.1,0.4-1.5c0.3-0.5,0.6-0.9,1.1-1.1c0.5-0.3,1-0.5,1.5-0.5
   c0.4,0,0.9,0,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6l-0.5,0.6c-0.5-0.4-1.1-0.6-1.8-0.6c-0.7,0-1.3,0.3-1.7,0.8c-0.2,0.2-0.4,0.5-0.5,0.8
   c-0.1,0.3-0.1,0.6-0.1,1c0,0.3,0.1,0.6,0.3,0.9c0.1,0.3,0.3,0.5,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.1,1,0.1
   C59.6,5.7,60.2,5.4,60.6,5z"
      />
      <path
        fill="#ffffff"
        d="M63.6,0.8l-0.1,1.6l2.8,0.1v0.8l-2.8-0.1l-0.1,2.4L67,5.7v0.8l-4.4-0.2L62.9,0l4.4,0.2V1L63.6,0.8z"
      />
      <path
        fill="#ffffff"
        d="M71.9,4.9l1,2.4L72,7.2l-1-2.3l-1.7-0.2L69,6.7l-0.8-0.1l0.9-6.3l2.6,0.4c0.3,0,0.5,0.1,0.8,0.3
   c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0.1,0.6,0,0.8c0,0.3-0.1,0.5-0.3,0.8c-0.1,0.2-0.3,0.4-0.6,0.6
   C72.5,4.7,72.2,4.8,71.9,4.9z M71.3,4.1c0.3,0,0.7,0,1-0.3c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3,0-0.7-0.3-1c-0.2-0.3-0.5-0.5-0.9-0.5
   l-1.8-0.3l-0.4,2.6L71.3,4.1z"
      />
      <path
        fill="#ffffff"
        d="M80.2,2.5L80,3.3l-1.9-0.5l-1.4,5.4L76,8l1.4-5.4l-1.9-0.5l0.2-0.8L80.2,2.5z"
      />
      <path fill="#ffffff" d="M79.9,9.1l2-6l0.8,0.2l-2,6L79.9,9.1z" />
      <path
        fill="#ffffff"
        d="M84.8,5l-0.6,1.5l2.6,1.1l-0.3,0.7l-2.6-1.1l-1.2,2.9l-0.7-0.3l2.4-5.9l4.1,1.7l-0.3,0.7L84.8,5z"
      />
      <path fill="#ffffff" d="M87.2,12.1l2.8-5.7l0.7,0.4l-2.8,5.7L87.2,12.1z" />
      <path
        fill="#ffffff"
        d="M92.6,8.7L91.8,10l2.4,1.4l-0.4,0.7l-2.4-1.4l-1.2,2l3.1,1.8l-0.4,0.7L89.1,13l3.2-5.5l3.8,2.2l-0.4,0.7
   L92.6,8.7z"
      />
      <path
        fill="#ffffff"
        d="M100.1,16.6c-0.2,0.3-0.6,0.6-0.9,0.8c-0.4,0.2-0.8,0.4-1.2,0.4c-0.4,0.1-0.8,0-1.2-0.1
   c-0.4-0.1-0.8-0.3-1.1-0.5L94,16l3.8-5.1l1.7,1.3c0.3,0.2,0.6,0.6,0.8,0.9c0.2,0.4,0.4,0.8,0.4,1.2c0.1,0.4,0,0.8-0.1,1.2
   C100.6,15.9,100.4,16.3,100.1,16.6L100.1,16.6z M99.5,16.1c0.4-0.5,0.5-1.1,0.4-1.8c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.4-0.5-0.6-0.7
   L98,12l-2.9,3.9l1,0.8c0.5,0.4,1.1,0.5,1.8,0.4C98.5,17,99.1,16.7,99.5,16.1L99.5,16.1z"
      />
      <path fill="#ffffff" d="M103.5,20.9l0.6-0.5l2.2,2.3l-0.6,0.6L103.5,20.9z" />
      <path
        fill="#ffffff"
        d="M110.9,33.8l-0.5-0.7l0.9-1.5l-3.5-2.3l-0.5-0.7l6.8-1.2l0.4,0.7L110.9,33.8z M111.8,30.9l1.7-2.7l-4.5,0.8
   L111.8,30.9z"
      />
      <path
        fill="#ffffff"
        d="M116.7,33.1l-1.4,0.7l1.3,2.5l-0.7,0.4l-1.3-2.6l-2.1,1.1l1.7,3.2l-0.7,0.4l-2-3.9l5.7-2.9l2,3.9l-0.7,0.4
   L116.7,33.1z"
      />
      <path
        fill="#ffffff"
        d="M117.3,43.5c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6
   l-0.9-2.3l0.7-0.3l0.9,2.3c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1
   c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0.1-0.3,0-0.4c0-0.1-0.1-0.3-0.2-0.4l-0.3-0.8c-0.2-0.4-0.2-0.9,0-1.4
   c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4
   c0.2,0.2,0.3,0.4,0.4,0.6l0.8,2l-0.7,0.3l-0.8-2c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.1-0.8,0c-0.2,0.1-0.4,0.3-0.5,0.5
   c-0.1,0.2-0.1,0.5,0,0.8l0.3,0.8c0.1,0.2,0.1,0.4,0.1,0.7s0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6C117.7,43.3,117.5,43.4,117.3,43.5
   z"
      />
      <path
        fill="#ffffff"
        d="M123.4,48.3l-0.8,0.2l-0.5-1.9l-5.4,1.5l-0.2-0.8l5.3-1.5l-0.5-1.9l0.8-0.2L123.4,48.3z"
      />
      <path
        fill="#ffffff"
        d="M124.6,54.9l-6.3,1l-0.1-0.8l3.4-0.5l-1.8-2.9l-2,0.3l-0.1-0.8l6.3-1L124,51l-3.3,0.5l1.8,2.9l2-0.3L124.6,54.9
   z"
      />
      <path
        fill="#ffffff"
        d="M124,57.7l-1.6,0.1l0.2,2.8h-0.8l-0.2-2.8l-2.4,0.1l0.2,3.6h-0.8l-0.2-4.4l6.3-0.3l0.2,4.4h-0.8L124,57.7z"
      />
      <path fill="#ffffff" d="M124.8,68H124l0.1-2l-5.6-0.3v-0.8l5.6,0.3l0.1-2h0.8L124.8,68z" />
      <path
        fill="#ffffff"
        d="M123.7,70.6l-1.6-0.2l-0.4,2.8l-0.8-0.1l0.4-2.8l-2.4-0.4l-0.5,3.6l-0.8-0.1l0.7-4.4l6.3,1l-0.7,4.4l-0.8-0.1
   L123.7,70.6z"
      />
      <path
        fill="#ffffff"
        d="M118,79.3c-0.5-0.1-0.9-0.4-1.1-0.8c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0-0.5,0-0.7l0.6-2.4l0.8,0.2l-0.6,2.3
   c-0.1,0.3,0,0.5,0.1,0.8c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.1,0.5,0,0.8-0.1c0.1-0.1,0.2-0.1,0.3-0.3
   c0.1-0.1,0.1-0.2,0.2-0.3l0.2-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.5,0,0.7,0
   c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.5,0.5c0.2,0.4,0.3,0.9,0.2,1.3l-0.6,2.2l-0.8-0.2l0.6-2.2c0.1-0.3,0-0.5-0.1-0.8
   c-0.1-0.2-0.4-0.4-0.6-0.5c-0.3-0.1-0.5,0-0.8,0.1c-0.1,0.1-0.2,0.1-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.8
   c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2C118.4,79.4,118.2,79.3,118,79.3z"
      />
      <path
        fill="#ffffff"
        d="M113.8,88.6l-0.8,0.3c-0.3-0.8-0.3-1.7,0.1-2.5c0.4-0.8,1-1.4,1.8-1.7c0.8-0.3,1.7-0.3,2.5,0.1s1.4,1,1.7,1.8
   c0.3,0.8,0.3,1.7-0.1,2.5c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.7,0.5-1.1,0.7l-0.3-0.7c0.5-0.2,0.9-0.5,1.1-0.9
   c0.3-0.4,0.5-0.9,0.5-1.4c0-0.5-0.1-1-0.4-1.4c-0.3-0.4-0.6-0.7-1.1-1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0-1,0.1
   c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.3-0.2,0.6-0.2,0.9C113.7,88,113.7,88.3,113.8,88.6z"
      />
      <path
        fill="#ffffff"
        d="M116,93.2l-1.4-0.8l-1.5,2.4l-0.7-0.4L114,92l-2-1.2l-1.9,3l-0.7-0.4l2.3-3.8l5.4,3.3l-2.3,3.8l-0.7-0.4
   L116,93.2z"
      />
      <path
        fill="#ffffff"
        d="M108.4,98.6l-2.6-0.2l0.6-0.7l2.5,0.2l1-1.4l-1.7-1.3l0.5-0.6l5.1,3.8l-1.6,2.1c-0.2,0.2-0.4,0.4-0.6,0.6
   c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.2-0.5-0.3-0.8
   C108.3,99.2,108.3,98.8,108.4,98.6z M109.4,98.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2-0.1,0.4,0,0.5c0,0.2,0.1,0.3,0.2,0.5
   c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4,0.1,0.5,0c0.2,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3l1.1-1.5
   l-2.1-1.6L109.4,98.4z"
      />
      <path
        fill="#ffffff"
        d="M106.5,106.9l-0.6-0.5l1.3-1.5l-4.1-3.8l0.5-0.6l4.1,3.8l1.3-1.4l0.6,0.5L106.5,106.9z"
      />
      <path fill="#ffffff" d="M100.8,103.5l4.3,4.7l-0.6,0.5l-4.3-4.7L100.8,103.5z" />
      <path
        fill="#ffffff"
        d="M102.1,109.8l-1-1.2l-2.2,1.8l-0.5-0.6l2.2-1.8l-2-2.5l0.6-0.5l4,5l-3.5,2.8l-0.5-0.6L102.1,109.8z"
      />
      <path fill="#ffffff" d="M94.7,108.5l3.6,5.2l-0.6,0.5l-3.6-5.2L94.7,108.5z" />
      <path
        fill="#ffffff"
        d="M95.1,114.9l-0.8-1.4l-2.4,1.4l-0.4-0.7l2.4-1.4l-1.2-2l-3.1,1.8l-0.4-0.7l3.8-2.3l3.2,5.5l-3.8,2.2l-0.4-0.7
   L95.1,114.9z"
      />
      <path
        fill="#ffffff"
        d="M84.5,117.6c-0.3-0.8-0.4-1.6-0.1-2.4c0.3-0.8,0.9-1.4,1.7-1.8l1.9-0.9l2.6,5.8l-1.9,0.9
   c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0-0.8-0.1-1.2-0.2C85.5,119,84.9,118.4,84.5,117.6z M85.3,117.3c0.3,0.6,0.7,1,1.3,1.2
   c0.6,0.2,1.2,0.2,1.8-0.1l1.2-0.5l-2-4.3l-1.2,0.5c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
   C85.1,116.7,85.1,117,85.3,117.3z"
      />
      <path fill="#ffffff" d="M79.1,118.5l0.2,0.8l-3,0.8l-0.2-0.8L79.1,118.5z" />
      <path
        fill="#ffffff"
        d="M64.3,118.7l0.9-0.1l0.8,1.6l3.8-1.9l0.9-0.1l-2.2,6.6l-0.8,0.1L64.3,118.7z M66.4,120.8l1.5,2.8l1.5-4.4
   L66.4,120.8z"
      />
      <path
        fill="#ffffff"
        d="M62.1,124.2v-1.6l-2.8-0.1v-0.8l2.8,0.1l0.1-2.4l-3.6-0.1v-0.8l4.4,0.1l-0.2,6.3l-4.4-0.1v-0.8L62.1,124.2z"
      />
      <path
        fill="#ffffff"
        d="M52.8,119.6c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.5-0.1,0.7,0
   l2.4,0.3l-0.1,0.8l-2.4-0.3c-0.3,0-0.5,0-0.7,0.2c-0.2,0.2-0.3,0.4-0.4,0.7c0,0.3,0,0.5,0.2,0.7c0.1,0.1,0.2,0.2,0.3,0.3
   c0.1,0.1,0.2,0.1,0.4,0.1l0.8,0.1c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.5,0,0.7
   c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0l-2.2-0.3l0.1-0.8l2.2,0.3
   c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0-0.2,0-0.3,0-0.5
   c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1l-0.8-0.1c-0.2,0-0.5-0.1-0.7-0.2
   c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.3-0.6C52.7,120.1,52.7,119.8,52.8,119.6L52.8,119.6z"
      />
      <path
        fill="#ffffff"
        d="M45.6,122.7l0.2-0.8l1.9,0.5L49,117l0.8,0.2l-1.3,5.4l1.9,0.5l-0.2,0.8L45.6,122.7z"
      />
      <path
        fill="#ffffff"
        d="M39.2,120.5l2.1-6l0.7,0.3L41,118l3.4-0.2l0.7-2l0.7,0.3l-2.1,6l-0.7-0.3l1.1-3.2l-3.4,0.2l-0.7,2L39.2,120.5z"
      />
      <path
        fill="#ffffff"
        d="M37,118.7l0.7-1.4l-2.5-1.2l0.3-0.7l2.5,1.2l1-2.1l-3.3-1.6l0.3-0.7l4,1.9l-2.8,5.7l-4-1.9l0.3-0.7L37,118.7z"
      />
      <path
        fill="#ffffff"
        d="M27.6,114.4l0.4-0.7l1.7,1l3-4.7l0.7,0.4l-2.9,4.7l1.7,1l-0.4,0.7L27.6,114.4z"
      />
      <path
        fill="#ffffff"
        d="M25.9,112.2l1-1.2l-2.2-1.7l0.5-0.6l2.2,1.7l1.5-1.9l-2.9-2.2l0.5-0.6l3.5,2.6l-3.9,5.1l-3.5-2.7l0.5-0.6
   L25.9,112.2z"
      />
      <path
        fill="#ffffff"
        d="M21.1,103c0.3-0.3,0.8-0.5,1.2-0.6c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l1.8,1.7l-0.5,0.6l-1.8-1.7
   c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.4
   c0.1,0.1,0.1,0.2,0.2,0.3l0.6,0.6c0.3,0.3,0.5,0.8,0.6,1.2c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4
   c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4l-1.6-1.5l0.5-0.6l1.6,1.5c0.1,0.1,0.2,0.2,0.3,0.2
   c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3l-0.6-0.6
   c-0.3-0.3-0.5-0.8-0.6-1.2c0-0.2,0-0.5,0.1-0.7C20.8,103.4,20.9,103.2,21.1,103z"
      />
      <path
        fill="#ffffff"
        d="M14.8,94.8l0.1-0.8c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.2,0.7,0.5,0.9,0.9c0.3,0.3,0.4,0.7,0.5,1.1
   c0.1,0.4,0.1,0.8,0.1,1.3c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.5,0.7-0.9,0.9c-0.3,0.3-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.1-1.3,0.1
   c-0.4-0.1-0.8-0.2-1.2-0.4c-0.4-0.2-0.7-0.5-0.9-0.9c-0.3-0.3-0.4-0.7-0.5-1.1c-0.1-0.4-0.1-0.8-0.1-1.3l0.8,0.1c-0.1,0.3,0,0.6,0,1
   c0.1,0.3,0.2,0.6,0.4,0.9c0.2,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0.1,0.6,0,1,0c0.3-0.1,0.6-0.2,0.9-0.4
   c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.3-0.6,0.3-0.9c0.1-0.3,0-0.6,0-1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.5-0.7-0.7
   C15.5,95,15.2,94.9,14.8,94.8z"
      />
      <path
        fill="#ffffff"
        d="M9.8,94.6l1.4-0.8l-1.4-2.4l0.7-0.4l1.4,2.4l2-1.2L12.1,89l0.7-0.4l2.2,3.8l-5.5,3.2l-2.1-3.8L8,91.4L9.8,94.6z
   "
      />
      <path
        fill="#ffffff"
        d="M8.8,85.3l1.5-2.2l0.4,0.9l-1.4,2l0.7,1.6l1.9-0.9l0.3,0.7L6.4,90l-1.1-2.4c-0.1-0.3-0.2-0.5-0.2-0.8
   c0-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.4-0.4,0.7-0.5s0.5-0.2,0.8-0.2c0.3,0,0.6,0,0.8,0.1
   C8.3,84.9,8.6,85.1,8.8,85.3z M8.5,86.3c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.1-0.7-0.1-1,0
   c-0.2,0.1-0.3,0.2-0.4,0.3C6.2,86,6.1,86.1,6,86.3c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.1,0.5L6.8,89l2.4-1.1L8.5,86.3z"
      />
      <path
        fill="#ffffff"
        d="M2.4,79.6l0.8-0.2l0.6,1.9L9,79.5l0.3,0.8L4,82l0.6,1.9l-0.8,0.2L2.4,79.6z"
      />
      <path fill="#ffffff" d="M8.1,76.3l-6.2,1.5L1.7,77l6.2-1.5L8.1,76.3z" />
      <path
        fill="#ffffff"
        d="M2,74.4l1.6-0.3L3,71.3l0.8-0.1L4.3,74l3.1-0.5l0.1,0.8l-6.3,1.1L0.6,71l0.8-0.1L2,74.4z"
      />
      <path fill="#ffffff" d="M6.7,68.5l-6.3,0.6l-0.1-0.8l6.3-0.6L6.7,68.5z" />
      <path
        fill="#ffffff"
        d="M0.9,65.8h1.6L2.4,63h0.8l0.1,2.8l2.4-0.1l-0.1-3.6h0.8l0.1,4.4l-6.3,0.1L0,62.2h0.8L0.9,65.8z"
      />
      <path
        fill="#ffffff"
        d="M3.6,55.2c0.8,0.1,1.6,0.5,2.2,1.1c0.5,0.6,0.8,1.5,0.8,2.3l-0.2,2.1L0,60.2l0.2-2.1c0.1-0.8,0.5-1.6,1.1-2.2
   C1.9,55.4,2.7,55.2,3.6,55.2L3.6,55.2z M3.5,56c-0.6,0-1.3,0.2-1.7,0.6C1.3,57,1,57.6,1,58.2l-0.1,1.3l4.8,0.4l0.1-1.3
   c0-0.6-0.2-1.3-0.6-1.7C4.8,56.4,4.2,56.1,3.5,56z"
      />
      <g>
        <path
          fill="#27CC0C"
          d="M51.1,90.7c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.6-0.9-2.2-1.5l0,0l-21.4-23c-0.5-0.9-0.7-1.8-0.6-2.8
       c0.1-1,0.4-1.9,1-2.7l0.1-0.1c1-0.9,2.2-1.4,3.5-1.5c1.2-0.2,2.4,0,3.5,0.6c0.2,0.2,4,3.1,12,9.3c3,2.3,5.4,4.2,5.7,4.4
       c0.3,0.3,0.7,0.4,1.2,0.4c0.4,0,0.8-0.2,1.1-0.5l39.3-36.8c1.1-1,2.4-1.6,3.8-1.6s2.8,0.5,3.8,1.5c0.5,0.5,0.9,1.1,1.2,1.7
       c0.3,0.7,0.4,1.4,0.4,2.1c0,0.7-0.1,1.4-0.4,2.1c-0.3,0.7-0.7,1.3-1.2,1.8L56,88.7c-0.7,0.7-1.4,1.2-2.2,1.5
       C52.9,90.6,52,90.7,51.1,90.7C51.1,90.7,51.1,90.7,51.1,90.7z M47.7,87.3c0.4,0.5,1,0.8,1.6,1.1c0.6,0.2,1.2,0.4,1.9,0.4
       c0,0,0,0,0,0c0.7,0,1.3-0.1,1.9-0.4c0.6-0.2,1.1-0.6,1.6-1.1l43.2-45.2c0.3-0.3,0.6-0.7,0.8-1.2c0.2-0.4,0.3-0.9,0.3-1.3
       c0-0.5-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.8-1.1c-1.3-1.3-3.5-1.3-4.8,0l-0.1,0.1L53.6,74.1c-0.6,0.6-1.5,1-2.4,1
       c-0.9,0-1.8-0.3-2.5-0.8c-0.3-0.2-2.7-2.1-5.7-4.4c-4.9-3.8-11.6-9-11.8-9.2c-0.6-0.3-1.3-0.4-2-0.3l-0.1,0c-0.8,0.1-1.7,0.4-2.3,1
       c-0.3,0.5-0.5,1-0.6,1.6c0,0.6,0.1,1.1,0.3,1.6L47.7,87.3z"
        />
      </g>
    </svg>
  );
};
