import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getNFTInfo } from '../../imports/scripts/xls20d/nft_handler.js';
import { INftData } from './nft.utils';
import { NftDetail } from './nft-detail';
import { NftMedia } from './nft-media';
import './nftData.scss';
import { Loader } from '../../components/loader';

const NftDataPage = () => {
  const history = useHistory();

  const { network, id, issuer }: any = useParams();
  const networkUrl = network === 'testnet' ? 'test.' : '';

  const [activeQuery, setActiveQuery] = useState(false);

  const [nftState, setNftState] = useState<{ error: boolean; data: INftData }>({
    error: false,
    data: null as unknown as INftData
  });

  const { isFetching: loading } = useQuery(
    'fetchNFTData',
    async () => {
      try {
        setActiveQuery(false);
        const data: any = await getNFTInfo(issuer, id, network);

        console.log(data);

        setNftState({
          ...nftState,
          data: {
            identifier: data.NFToken_obj.TokenID,
            actual_nft_owner: data.actual_nft_owner,
            detected_hot_wallet_obj: data.content_object_url,
            metadata_cid: data.NFToken_uris_obj.cid,
            detected_cti: data.content_link,
            metadata_tx_hash: data.NFToken_obj.URI,
            name: data.metadata.name,
            description: data.metadata.description,
            author: data.metadata.author.name,
            issuer_address: data.metadata.issuer,
            issuer_domain: data.issuer_domain,
            nft_serial: data.NFToken_obj.nft_serial,
            metadata_link: data.metadata_link,
            content_link: data.content_link,
            content_link_blob: data.content_object_url,
            content_cid: data.metadata.content.substr(4),
            NFToken_uris_obj: data.NFToken_uris_obj,
            NFToken_flags: data.NFToken_flags,
            warnings: data.warnings
          }
        });
      } catch (e) {
        console.error('NFTData.tsx exception: ', e);
        setNftState({
          ...nftState,
          error: true
        });
      }
    },
    {
      enabled: activeQuery
    }
  );

  useEffect(() => {
    if (nftState.error) {
      history.push(`/${network}/error`);
    }
    if (issuer && id) {
      setActiveQuery(true);
    }
  }, [id, issuer, history, nftState.error, network]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <Loader color="#ffffff" />
        </div>
      )}
      {nftState.data && (
        <div className="container nft-container">
          <NftDetail nftData={nftState.data} networkUrl={networkUrl} />
          <NftMedia nftData={nftState.data} />
        </div>
      )}
    </>
  );
};

export default NftDataPage;
