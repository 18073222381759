import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/logo';
import Copy from '../copy';
import Social from '../social';
import { MdClose } from 'react-icons/md';
import { openExternal } from '../../imports/scripts/xumm_handler';

type NavMobileProps = {
  onClose: () => void;
  height: number | null;
};

export const NavMobile = (props: NavMobileProps) => {
  const location = useLocation();
  const buttonHomeActive = location.pathname === '/';
  const buttonVisualizerActive = location.pathname === '/XLS20d';

  return (
    <div className="navigation-mobile" style={{ height: `${props.height}px` }}>
      <div onClick={props.onClose}>
        <Link to="/" className={`logo ${buttonHomeActive ? 'disabled' : ''}`}>
          <Logo />
        </Link>
      </div>
      <div className="menu-nav">
        <Link
          to="/XLS20d"
          className={`nav-item ${buttonVisualizerActive ? 'disabled' : ''}`}
          onClick={props.onClose}
        >
          THE NFT VISUALIZER
        </Link>
        <a
          href="https://www.aesthetes.art"
          className="nav-item"
          onClick={(e) => {
            e.preventDefault();
            openExternal('https://www.aesthetes.art');
          }}
        >
          AESTHETES PROJECT
        </a>
      </div>
      <div className="social-nav">
        <Social />
        <Copy />
      </div>

      <div className="close-button" onClick={props.onClose}>
        <MdClose color="white" size="1.25rem" />
      </div>
    </div>
  );
};
