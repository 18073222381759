export const IconDownload = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 40 40">
      <path
        fill="#ffffff"
        d="M26.9,31.2l-6.4,5.7c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.4,0.1s-0.2,0-0.4-0.1
   c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0l-6.4-5.7c-0.3-0.3-0.3-0.7-0.1-1c0.3-0.3,0.7-0.3,1-0.1l5.2,4.6V15c0-0.4,0.3-0.7,0.7-0.7
   c0.4,0,0.7,0.3,0.7,0.7v19.8l5.2-4.6c0.3-0.3,0.7-0.2,1,0.1C27.2,30.5,27.2,30.9,26.9,31.2z"
      />
      <path
        fill="#ffffff"
        d="M33.6,25.1c-0.4,0.4-0.9,0.6-1.5,0.6h-5.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h5.7
   c0.3,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5V5.1c0-0.3-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2H7.9c-0.3,0-0.4,0.1-0.5,0.2
   C7.3,4.6,7.2,4.8,7.2,5.1v18.5c0,0.3,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2h5.7c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H7.9
   c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5l0-18.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6l24.2,0
   c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5v18.5C34.2,24.1,34,24.7,33.6,25.1z"
      />
    </svg>
  );
};
