import { useEffect, useRef, useState } from 'react';
import { NftDetailMenu } from './nft-detail-menu';
import { INftData } from './nft.utils';
import { openExternal } from '../../imports/scripts/xumm_handler';

const nl2br = require('react-newline-to-break');

type NftDetailProps = {
  nftData: INftData;
  networkUrl: string;
};

function doOpenExternal(e: any, url: string) {
  e.preventDefault();
  openExternal(url);
}

export const NftDetail = (props: NftDetailProps) => {
  const { nftData } = props;

  const [expanded, setExpanded] = useState<boolean>(false);
  const [canExpand, setCanExpand] = useState<boolean>(false);

  const descRef = useRef<HTMLParagraphElement>(null);

  const checkCanExpand = () => {
    setCanExpand(descRef.current!.offsetHeight > 130);
  };

  useEffect(() => {
    checkCanExpand();
  }, []);

  return (
    <div className="nft-detail">
      <NftDetailMenu nftData={nftData} />
      <div className="title">
        <b>Artwork:</b> {nftData?.name}
      </div>
      <div className="author">
        <b>Author:</b> {nftData?.author}
      </div>
      <div className="description">
        <b>Description:</b>
        <div className={` description-container ${expanded ? 'expanded' : ''}`}>
          <p ref={descRef}>{nl2br(nftData?.description)}</p>
        </div>
        {canExpand && (
          <div className="read-more" onClick={() => setExpanded(!expanded)}>
            READ FULL DESCRIPTION
          </div>
        )}
      </div>

      <div className="metadata">
        <a
          className="metadata-item  button-hover"
          onClick={(e) => doOpenExternal(e, `${nftData.content_link}`)}
          href={nftData.content_link}
          rel="noreferrer"
        >
          DIGITAL ARTWORK
        </a>
        <a
          className="metadata-item button-hover"
          onClick={(e) => doOpenExternal(e, `${nftData.metadata_link}`)}
          href={nftData.metadata_link}
          rel="noreferrer"
        >
          NFT METADATA
        </a>
        <a
          className="metadata-item button-hover"
          onClick={(e) =>
            doOpenExternal(e, `https://bithomp.com/explorer/${nftData.issuer_address}`)
          }
          href={`https://bithomp.com/explorer/${nftData.issuer_address}`}
          rel="noreferrer"
        >
          ISSUER ADDRESS
        </a>
        <a
          className="metadata-item  button-hover"
          onClick={(e) =>
            doOpenExternal(e, `https://bithomp.com/explorer/${nftData.actual_nft_owner}`)
          }
          href={`https://bithomp.com/explorer/${nftData.actual_nft_owner}`}
          rel="noreferrer"
        >
          OWNER ACCOUNT
        </a>
      </div>
    </div>
  );
};
