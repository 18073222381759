export const Copy = () => {
  return (
    <div className="copy">
      <b>
        <i>2022 Copyright</i>
      </b>
      &nbsp;- All rights reserved
    </div>
  );
};
