export const IconSwipe = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 30 30">
      <path
        fill="white"
        d="M10.9,16.9c0-0.4,0.1-0.7,0.1-0.9c0-2.7,0-5.5,0-8.2c0-0.2,0-0.5,0-0.7c0.1-1.4,1-2.3,2.4-2.2
c1.2,0,2.1,1,2.2,2.3c0,1.3,0,2.6,0,3.9v2c1.4-0.5,2.5-0.2,3.3,0.9c1.3-0.8,2.5-0.5,3.4,0.6c0.3-0.1,0.5-0.2,0.7-0.3
c1.3-0.5,2.9,0.4,3,1.8c0.1,2,0.2,4.1-0.1,6.1c-0.5,3.6-2.8,5.7-6.2,6.6c-3,0.9-5.8,0.2-8-2.1c-1.1-1.2-2.2-2.4-3.1-3.8
C7.3,21.1,6,19.4,4.5,18c-0.4-0.4-0.5-0.8-0.4-1.4c0.3-1.1,1.1-1.6,2.1-1.8c1.4-0.2,2.5,0.3,3.6,1.1C10.1,16.2,10.5,16.5,10.9,16.9z
 M5.1,17c0.1,0.1,0.2,0.3,0.3,0.4c1.5,1.5,2.9,3.1,4,4.9c1,1.6,2.2,3,3.6,4.2c0.8,0.6,1.6,1.1,2.6,1.3c2.6,0.5,4.8-0.1,6.8-1.8
c1.3-1.1,2.2-2.6,2.3-4.4c0.1-1.8,0-3.5,0-5.3c0-0.7-0.5-1.2-1.1-1.2c-0.6,0-1.2,0.5-1.3,1.2c0,0.2,0,0.5,0,0.7
c-0.1,0.2-0.3,0.4-0.5,0.4c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.1,0-0.4,0-0.5c0-0.3,0-0.6,0-0.9c-0.1-0.7-0.6-1.2-1.2-1.1
c-0.6,0-1.1,0.5-1.1,1.1c0,0.2,0,0.4,0,0.6c0,0.3-0.1,0.6-0.5,0.6c-0.4,0-0.5-0.3-0.5-0.7c0-0.5,0-0.9,0-1.4
c-0.1-0.7-0.6-1.1-1.3-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0.3,0,0.6,0,1c0,0.4,0.1,0.9-0.5,1c-0.4,0.1-0.6-0.3-0.6-1c0-2.8,0-5.5,0-8.3
c0-0.2,0-0.5,0-0.7c-0.1-0.6-0.5-1-1.1-1c-0.3,0-0.5,0-0.8,0.2c-0.2,0.2-0.4,0.4-0.5,0.6C12,7,12,7.3,12,7.6c0,2.3,0,4.6,0,6.8
c0,1.3,0,2.6,0,3.9c0,0.3,0,0.7-0.4,0.8c-0.4,0.1-0.6-0.2-0.8-0.4c-0.4-0.4-0.7-0.9-1.1-1.3c-0.7-0.7-1.5-1.3-2.6-1.5
C6.2,15.6,5.4,16,5.1,17z"
      />
      <path
        fill="white"
        d="M7.1,4.2c0.3,0,0.7-0.1,1,0c0.2,0.1,0.4,0.3,0.5,0.5c0,0.1-0.3,0.5-0.4,0.5c-1,0-2,0-3,0
c-0.1,0-0.4-0.3-0.4-0.5c0-0.9,0-1.8,0-2.6c0-0.3,0.2-0.6,0.5-0.6c0.4,0,0.5,0.3,0.5,0.6c0,0.4,0,0.8,0,1.4c0.6-0.4,1.1-0.7,1.6-1
c4.3-2.4,9.7-1.8,13.4,1.5c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.5-0.1,0.6s-0.5,0.1-0.6,0.1c-0.3-0.1-0.5-0.4-0.7-0.6
c-1.7-1.4-3.8-2.3-6-2.5c-2.2-0.2-4.4,0.3-6.3,1.5C7.6,3.6,7.3,3.8,7.1,4C7.1,4,7.1,4.1,7.1,4.2z"
      />
    </svg>
  );
};
