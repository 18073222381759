import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import Header from './components/header';
import Footer from './components/footer/Footer';

import './App.scss';
import { Routes } from './routes';
import { AppLoader } from './pages/app-loader/app-loader';
import { useEffect, useState } from 'react';
import { useWindowSize } from './hook/useWindowSize';

function App() {
  ReactGA.initialize('UA-211269498-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [showApp, setShowApp] = useState<boolean>(false);

  const size = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setShowApp(true);
    }, 2000);
  }, []);

  return (
    <Router>
      <AppLoader showApp={showApp} />
      <div
        className={`app-wrapper ${showApp ? 'visible' : ''}`}
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`
        }}
      >
        <Header />
        <Routes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
