import { INftData } from './nft.utils';
import { BsThreeDots } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdCheck } from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';

type NftDetailMenuProps = {
  nftData: INftData;
};

export const NftDetailMenu = (props: NftDetailMenuProps) => {
  const { nftData } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const dropdown = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (!dropdown.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="detail-menu">
        <div className="detail-menu-wrapper" ref={dropdown}>
          <div className="detail-button" onClick={() => setOpen(!open)}>
            <BsThreeDots color="white" size="1.5rem" />
          </div>
          {open && (
            <div className="detail-menu-content">
              <div className="detail-menu-item">
                <div className="inner">
                  <span>Taxon</span>
                  {nftData.NFToken_uris_obj.NFTokenTaxon}
                </div>
              </div>
              <div className="detail-menu-item">
                <div className="inner">
                  <span>Sequence number</span>
                  {nftData.nft_serial}
                </div>
              </div>
              <div className="detail-menu-item">
                <div className="inner withFlag" onClick={() => setExpanded(!expanded)}>
                  <div className={`flag ${expanded ? 'expanded' : ''} `}>
                    <MdKeyboardArrowDown color="white" size="1.5rem" />
                  </div>
                  Flags
                </div>
                {expanded && (
                  <div className="flag-container">
                    <div className="flag-item">
                      <div className="checkbox">
                        {nftData.NFToken_flags.tfBurnable && (
                          <MdCheck color="white" size="1.25rem" />
                        )}
                      </div>
                      tfBurnable
                    </div>
                    <div className="flag-item">
                      <div className="checkbox">
                        {nftData.NFToken_flags.tfTrustLine && (
                          <MdCheck color="white" size="1.25rem" />
                        )}
                      </div>
                      tfTrustLine
                    </div>
                    <div className="flag-item">
                      <div className="checkbox">
                        {nftData.NFToken_flags.tfOnlyXRP && (
                          <MdCheck color="white" size="1.25rem" />
                        )}
                      </div>
                      tfOnlyXRP
                    </div>
                    <div className="flag-item">
                      <div className="checkbox">
                        {nftData.NFToken_flags.tfTransferable && (
                          <MdCheck color="white" size="1.25rem" />
                        )}
                      </div>
                      tfTransferable
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
