export const IconShare = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 24 24">
      <g>
        <path
          fill="#ffffff"
          d="M16.9,4.8c-0.2,0.2-0.5,0.2-0.7,0l-3.7-3.3v13.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V1.6L7.8,4.9
       C7.6,5.1,7.3,5,7.1,4.8C6.9,4.6,7,4.3,7.2,4.1l4.5-4c0-0.1,0.1-0.1,0.2-0.1C11.9,0,12,0,12,0s0.1,0,0.2,0c0,0,0,0,0,0
       c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0l4.5,4C17,4.3,17.1,4.6,16.9,4.8z"
        />
        <path
          fill="#ffffff"
          d="M2.4,8.4C2.7,8.1,3.1,8,3.5,8h4C7.8,8,8,8.2,8,8.5S7.8,9,7.5,9h-4C3.3,9,3.2,9.1,3.1,9.1C3.1,9.2,3,9.3,3,9.5
       v13c0,0.2,0.1,0.3,0.1,0.4C3.2,22.9,3.3,23,3.5,23h17c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4v-13c0-0.2-0.1-0.3-0.1-0.4
       C20.8,9.1,20.7,9,20.5,9h-4C16.2,9,16,8.8,16,8.5S16.2,8,16.5,8h4c0.4,0,0.8,0.1,1.1,0.4C21.9,8.7,22,9.1,22,9.5v13
       c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.4-1.1,0.4h-17c-0.4,0-0.8-0.1-1.1-0.4C2.1,23.3,2,22.9,2,22.5v-13C2,9.1,2.1,8.7,2.4,8.4z"
        />
      </g>
    </svg>
  );
};
