import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { TiSocialFacebook, TiSocialLinkedin } from 'react-icons/ti';
import { AiOutlineYoutube } from 'react-icons/ai';

export const Social = () => {
  return (
    <div className="social">
      <a
        href="https://twitter.com/aesthetes_art"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <FaTwitter color="white" />
      </a>

      <a
        href="https://www.linkedin.com/company/71714406/admin/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <TiSocialLinkedin color="white" size="1.5rem" />
      </a>
      <a
        href="https://www.instagram.com/aesthetes_fineart/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <FaInstagram color="white" size="1.5rem" />
      </a>

      <a
        href="https://www.facebook.com/aesthetes.fineart/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <TiSocialFacebook color="white" size="1.5rem" />
      </a>

      <a
        href="https://www.youtube.com/c/Aesthetes"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon"
      >
        <AiOutlineYoutube color="white" size="1.5rem" />
      </a>
    </div>
  );
};
